import * as sounds from "../sounds/*.mp3";

const codeSolution = 573;
const audioSolution = ["police", "pompiers", "gendarmerie", "ambulance"];

let playingAudio;

document.addEventListener("DOMContentLoaded", () => {
  document.getElementById("submit-code").addEventListener("click", () => {
    try {
      const code = parseInt(document.getElementById("code").value);
      if (code === codeSolution) onFoundCode();
    } catch (e) {}
    document.getElementById("code").value = "";
  });

  const buttons = document.querySelectorAll(".sound-button");
  buttons.forEach((button) => {
    button.addEventListener("click", () => {
      if (playingAudio) {
        playingAudio.pause();
      }

      const name = button.getAttribute("data-sound");
      playingAudio = new Audio(sounds[name]);
      playingAudio.play();

      updateLastPlayed(name);
    });
  });
});

let lastPlayedNames = [];
function updateLastPlayed(name) {
  lastPlayedNames.push(name);
  if (lastPlayedNames.length > 4) {
    lastPlayedNames.shift();
    console.assert(lastPlayedNames.length <= 4);
  }

  if (
    lastPlayedNames.length === 4 &&
    lastPlayedNames.every((value, index) => audioSolution[index] === value)
  ) {
    onEnd();
  }
}

function onFoundCode() {
  document.getElementById("code-entry").classList.add("display-none");
  document.getElementById("soundboard").classList.remove("display-none");
}

function onEnd() {
  document.getElementById("soundboard").classList.add("display-none");
  document.getElementById("end").classList.remove("display-none");
}
